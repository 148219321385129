const HomeLoading = ({ children, isLoading }) => {
  return (
    <div>
      <div
        className='data-[state=hidden]:hidden data-[state=show]:block animate-loading'
        data-state={isLoading ? 'show' : 'hidden'}
      >
        <div className='animate-pulse flex justify-between gap-x-10'>
          <div className='w-full sm:w-7/12 lg:w-full'>
            <div className='flex flex-wrap items-center mb-4'>
              <div className='ml-1 text-left w-full'>
                <div className='grid grid-cols-1 gap-y-4 h-[64px] md:h-[72px] lg:h-[96px] 2xl:h-[120px] mb-8'>
                  <div class='h-full bg-gray-300 rounded-md w-full'></div>
                  <div class='h-full bg-gray-300 rounded-md w-8/12'></div>
                </div>
                <div className='grid grid-cols-1 gap-y-2 lg:gap-y-2 h-[230px] sm:h-[144px] md:h-[192px] lg:h-[196px] 2xl:h-[168px]'>
                  <div class='h-full bg-gray-300 rounded-md w-full'></div>
                  <div class='h-full bg-gray-300 rounded-md w-11/12'></div>
                  <div class='h-full bg-gray-300 rounded-md w-full'></div>
                  <div class='h-full bg-gray-300 rounded-md w-10/12'></div>
                  <div class='h-full bg-gray-300 rounded-md w-11/12'></div>
                  <div class='h-full bg-gray-300 rounded-md w-10/12 block xl:hidden'></div>
                  <div class='h-full bg-gray-300 rounded-md w-full lg:w-8/12 xl:w-4/12'></div>
                  <div class='h-full block lg:hidden bg-gray-300 rounded-md w-full md:w-8/12'></div>
                  <div class='h-full block sm:hidden bg-gray-300 rounded-md w-8/12'></div>
                </div>
              </div>
            </div>
            <div className='flex gap-x-4 h-[40px]'>
              <div class='h-full bg-gray-300 rounded-full w-32 mb-4'></div>
              <div class='h-full bg-gray-300 rounded-md w-32 mb-4'></div>
            </div>
          </div>
          <div className='w-full hidden lg:block'>
            <div className='grid h-full grid-cols-1 content-between justify-end'>
              <div class='h-16 bg-gray-300 rounded-md w-96'></div>
              <div class='h-16 bg-gray-300 rounded-md w-44'></div>
              <div class='h-16 bg-gray-300 rounded-md w-64'></div>
              <div class='h-16 bg-gray-300 rounded-md w-full'></div>
            </div>
          </div>
        </div>
      </div>
      <div className='data-[state=hidden]:hidden data-[state=show]:block' data-state={isLoading ? 'hidden' : 'show'}>
        {children}
      </div>
    </div>
  );
};

export default HomeLoading;
