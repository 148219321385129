const features = [
  {
    title: 'Tin tức',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Các nội dung, tin tức luôn được cập nhật lên Tin tức để người dùng có thể theo dõi hàng ngày.'
  },
  {
    title: 'Lịch công tác',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Xem trực tiếp các lịch công tác của bạn trên ứng dụng Sổ tay Đảng viên.'
  },
  {
    title: 'Văn bản',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Các văn bản đã được ban hành liên tục cập nhật'
  },
  {
    title: 'Học tập',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Cho phép người dùng học tập và thi trực tuyến trên ứng dụng Sổ tay Đảng viên.'
  },
  {
    title: 'Tư liệu',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Bao gồm các tư liệu, văn kiện về Đảng cộng sản Việt Nam.'
  },
  {
    title: 'Thông tin',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Xem thông tin cá nhân trên ứng dụng Sổ tay Đảng viên.'
  },
  {
    title: 'Tin tức',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Các nội dung, tin tức luôn được cập nhật lên Tin tức để người dùng có thể theo dõi hàng ngày.'
  },
  {
    title: 'Lịch công tác',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Xem trực tiếp các lịch công tác của bạn trên ứng dụng Sổ tay Đảng viên.'
  },
  {
    title: 'Văn bản',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Các văn bản đã được ban hành liên tục cập nhật'
  },
  {
    title: 'Học tập',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Cho phép người dùng học tập và thi trực tuyến trên ứng dụng Sổ tay Đảng viên.'
  },
  {
    title: 'Tư liệu',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Bao gồm các tư liệu, văn kiện về Đảng cộng sản Việt Nam.'
  },
  {
    title: 'Thông tin',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Xem thông tin cá nhân trên ứng dụng Sổ tay Đảng viên.'
  }
];

export default features;
