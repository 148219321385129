import React from 'react';
import Modal from 'components/utils/Modal';

export default function VideoModal({ isOpen, setIsOpen, videoSrc }) {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} title='Ứng dụng Sổ tay Đảng viên điện tử'>
      <div className='w-full lg:w-[700px]'>
        <div
          className='w-full'
          style={{
            position: 'relative',
            paddingBottom: '56.25%' /* 16:9 */,
            paddingTop: 0,
            height: 0
          }}
        >
          {isOpen && (
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              src={videoSrc}
              title='Ứng dụng Sổ tay Đảng viên điện tử'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowfullscreen='allowfullscreen'
              mozallowfullscreen='mozallowfullscreen'
              msallowfullscreen='msallowfullscreen'
              oallowfullscreen='oallowfullscreen'
              webkitallowfullscreen='webkitallowfullscreen'
            ></iframe>
          )}
        </div>
      </div>
    </Modal>
  );
}
