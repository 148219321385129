import { Routes, Route } from 'react-router-dom';
import { routes } from 'routes';
import ScrollToTop from 'components/utils/ScrollToTop';
import { NewShareModalContextProvider } from 'context/new-share-modal.context';
import { ToastContextProvider } from 'context/toast.context';
import React, { Suspense, lazy } from 'react';
import HeroBannerSuspense from 'components/utils/HeroBannerSuspense';

const Home = lazy(() => import('pages/home'));
const MainLayout = lazy(() => import('components/layout/MainLayout'));
const NewsLayout = lazy(() => import('components/layout/NewsLayout'));
const NewsCategory = lazy(() => import('pages/news/news-category'));
const NewsDetails = lazy(() => import('pages/news/news-details'));

function App() {
  return (
    <>
      <ToastContextProvider>
        <NewShareModalContextProvider>
          <ScrollToTop />
          <Routes>
            <Route
              path='/'
              element={
                <Suspense fallback={null}>
                  <MainLayout />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<HeroBannerSuspense />}>
                    <Home />
                  </Suspense>
                }
              />
              {routes.map(
                ({ path, element }, key) =>
                  element && (
                    <Route key={key} exact path={path} element={<Suspense fallback={null}>{element}</Suspense>} />
                  )
              )}
              <Route
                element={
                  <Suspense fallback={null}>
                    <NewsLayout />
                  </Suspense>
                }
              >
                <Route
                  exact
                  path='/tin-tuc/:chuyen_muc?'
                  element={
                    <Suspense fallback={null}>
                      <NewsCategory />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path='/tin-tuc/:chuyen_muc/:tin_tuc_slug?'
                  element={
                    <Suspense fallback={null}>
                      <NewsDetails />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Routes>
        </NewShareModalContextProvider>
      </ToastContextProvider>
    </>
  );
}

export default App;
