import React, { createContext, useContext, useState } from 'react';

const ToastContext = createContext();

export function ToastContextProvider({ children }) {
  const [toastContent, setToastContent] = useState({
    isOpen: false,
    error: false,
    message: ''
  });

  return <ToastContext.Provider value={{ toastContent, setToastContent }}>{children}</ToastContext.Provider>;
}

export function useToastContext() {
  const toastContext = useContext(ToastContext);

  if (!toastContext) {
    throw new Error('useToastContext must be used within a ToastContextProvider');
  }

  return toastContext;
}
