import React, { useState, useEffect, useRef } from 'react';

export default function AnimatedComponent({ children, animatedClass }) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const checkIsVisible = () => {
    const element = ref.current;
    const rect = element.getBoundingClientRect();
    setIsVisible(rect.top <= window.innerHeight + 100 && rect.bottom >= -100);
  };

  useEffect(() => {
    window.addEventListener('scroll', checkIsVisible);
    checkIsVisible();
    return () => {
      window.removeEventListener('scroll', checkIsVisible);
    };
  }, []);

  const animationConfig = {
    fadeIn: 'animate-fadeIn',
    fadeOut: 'animate-fadeOut',
    slideInUp: 'animate-slideInUp',
    slideInDown: 'animate-slideInDown',
    slideInLeft: 'animate-slideInLeft',
    slideInRight: 'animate-slideInRight',
    zoomIn: 'animate-zoomIn',
    zoomOut: 'animate-zoomOut'
  };

  return (
    <div ref={ref} className={`w-full ${isVisible ? animationConfig[animatedClass] : ''}`} data-visible={isVisible}>
      {children}
    </div>
  );
}
