import React, { Suspense, lazy } from 'react';
import whySTDV from 'data/why-stdv';
import features from 'data/features';
import technologies from 'data/technologies';
import HeroBanner from 'components/home/HeroBanner';
import HeroBannerSuspense from 'components/utils/HeroBannerSuspense';

const WhySTDV = lazy(() => import('components/home/WhySTDV'));
const Features = lazy(() => import('components/home/Features'));
const Technologies = lazy(() => import('components/home/Technologies'));
const Downloads = lazy(() => import('components/home/Downloads'));

export default function Home() {
  return (
    <>
      <Suspense fallback={<HeroBannerSuspense />}>
        <HeroBanner
          bgImgSrc='https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Fbanner1.png?alt=media&token=e0580bf8-cc28-4d02-8f66-70b90dfbd87e&_gl=1*17jj3v8*_ga*OTU4ODE0NDM3LjE2OTg2MzU4MDY.*_ga_CW55HF8NVT*MTY5ODYzODQzMS4yLjAuMTY5ODYzODQzMS42MC4wLjA.'
          title='Tương lai của ứng dụng Sổ tay Đảng viên điện tử'
          subTitle='Trong thời đại công nghệ thông tin hiện nay, việc cung cấp đầy đủ, kịp thời cho cán bộ, đảng viên những
                thông tin chính thống có ý nghĩa rất quan trọng.​ Nhận thức rõ điều đó, Trung tâm CNTT MobiFone, đã xây
                dựng và đưa vào triển khai phần mềm “Sổ tay đảng viên điện tử”. Đặc biệt, việc đưa vào sử dụng phần mềm
                “Sổ tay đảng viên điện tử” là bước đột phá, góp phần tạo sự thống nhất trong Đảng, đồng thuận trong xã
                hội.'
          videoSrc='https://www.youtube.com/embed/ZXE3ebzlZe8?si=Manx1wP9CyvQnvaS'
        />
      </Suspense>
      <Suspense fallback={null}>
        <WhySTDV
          title={
            <p className='text-4xl lg:text-5xl leading-none'>
              Tại sao nên chọn <span className='text-primary'>sổ tay Đảng viên điện tử</span>
            </p>
          }
          subTitle='Ứng dụng dành riêng cho các Đảng viên, nhằm giúp Đảng viên có thể theo dõi thông tin, văn kiện, văn bản
        của Đảng bộ và dễ dàng thực hiện nghị quyết học tập và thi trực tuyến trên ứng dụng Sổ tay Đảng viên điện
        tử.'
          whySTDV={whySTDV}
        />
      </Suspense>
      <Suspense fallback={null}>
        <Features
          title='Sổ tay Đảng viên điện tử'
          subTitle='Tính năng ứng dụng'
          imgSrc='https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2FFrame%202071856270.png?alt=media&token=ec580d80-bb84-4d5c-a800-e0b52cfb19ac'
          features={features}
        />
      </Suspense>
      <Suspense fallback={null}>
        <Technologies
          title='Sổ tay Đảng viên điện tử'
          subTitle='Áp dụng công nghệ tương lai'
          imgSrc='https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2FGroup%202071856055%20(1).png?alt=media&token=68536a9e-65cc-47c7-85a5-3be84a319323&_gl=1*guo700*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODc0NTgxOC40LjEuMTY5ODc0NTgxOS41OS4wLjA.'
          technologies={technologies}
        />
      </Suspense>
      <Suspense fallback={null}>
        <Downloads
          title='Sổ tay Đảng viên điện tử'
          subTitle='Đồng hành và trải nghiệm cùng với chúng tôi'
          imgSrc='https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2FGroup%202071856069%201.png?alt=media&token=717bf01c-e83b-4934-8757-21e008c7d543'
          appStoreDownload={{
            imgSrc:
              'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2FApp%20Store%20MBF.png?alt=media&token=a9e61669-8268-49d2-8353-411c1f9db1c4&_gl=1*16e7nqf*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODgwMzYxMC41LjEuMTY5ODgwMzg4OC42MC4wLjA.',
            href: 'https://apps.apple.com/vn/app/s%E1%BB%95-tay-%C4%91%E1%BA%A3ng-vi%C3%AAn-mobifone/id1665853988?l=vi'
          }}
          chPlayDownload={{
            imgSrc:
              'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2FCH%20Play%20MBF.png?alt=media&token=c47fefe1-a40a-4499-9b69-cc56b4e37cd8&_gl=1*1sitbgp*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODgwMzYxMC41LjEuMTY5ODgwMzg5Ni41Mi4wLjA.',
            href: 'https://play.google.com/store/apps/details?id=com.sotaydangvien&pcampaignid=web_share'
          }}
        />
      </Suspense>
    </>
  );
}
