import React, { createContext, useContext, useState } from 'react';

const NewShareModalContext = createContext();

export function NewShareModalContextProvider({ children }) {
  const [isOpenNewShareModal, setIsOpenNewShareModal] = useState(false);
  const [newShareData, setNewShareData] = useState(null);

  return (
    <NewShareModalContext.Provider
      value={{ isOpenNewShareModal, setIsOpenNewShareModal, newShareData, setNewShareData }}
    >
      {children}
    </NewShareModalContext.Provider>
  );
}

export function useNewShareModalContext() {
  const newShareModalContext = useContext(NewShareModalContext);

  if (!newShareModalContext) {
    throw new Error('useNewShareModalContext must be used within a NewShareModalContextProvider');
  }

  return newShareModalContext;
}
