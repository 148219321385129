import Home from './pages/home';
import News from 'pages/news/news';
// import Contact from 'pages/contact';

export const routes = [
  {
    name: 'Trang chủ',
    path: '/',
    element: <Home />
  },
  {
    name: 'Tin tức',
    path: '/tin-tuc',
    element: <News />
  }
  // {
  //   name: 'Liên hệ',
  //   path: '/lien-he',
  //   element: <Contact />
  // }
];

export default routes;
