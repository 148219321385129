import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useRef } from 'react';

export default function Modal({ children, isOpen, setIsOpen, title }) {
  const modalContent = useRef(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalContent.current && !modalContent.current.contains(event.target)) {
        closeModal();
      }
    }
    function handleKeyDown(event) {
      const { key, keyCode } = event;
      if (key === 'Escape' || keyCode === '27') closeModal();
    }
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
    };
  }, [modalContent]);

  return (
    <div
      className='fixed top-0 left-0 right-0 z-50 w-full p-2 overflow-x-hidden overflow-y-auto md:inset-0 h-full max-h-full bg-white bg-opacity-50 items-center justify-center hidden data-[state=open]:flex animate-openNav'
      data-state={isOpen ? 'open' : 'close'}
    >
      <div
        ref={modalContent}
        className='relative bg-cover bg-no-repeat bg-hero-banner w-full lg:w-fit max-w-[1000px] h-fit px-4 pt-4 pb-6 lg:px-6 lg:pt-6 rounded-lg shadow-[3px_3px_5px_0px_rgba(0,0,0,0.25)]'
      >
        <div className='flex items-center justify-between mb-4 text-white'>
          <h3 className='text-sm md:text-xl lg:text-2xl font-semibold'>{title}</h3>
          <XMarkIcon
            className='w-7 h-7 ml-auto mr-0 cursor-pointer hover:opacity-60 transition-opacity duration-100'
            onClick={closeModal}
          />
        </div>
        {children}
      </div>
    </div>
  );
}
