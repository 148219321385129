import { PlayCircleIcon } from '@heroicons/react/24/solid';
import AnimatedComponent from '../utils/AnimatedComponent';
import HomeLoading from 'components/layout/loading/HomeLoading';
import { useEffect, useState } from 'react';
import VideoModal from 'components/utils/VideoModal';

const HeroBanner = ({ bgImgSrc, title, subTitle, videoSrc }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, [1000]);
  }, []);

  const handleScrollToDownload = () => {
    const downloadElement = document.getElementById('download');
    downloadElement.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='relative flex content-center items-center justify-center h-fit lg:h-screen bg-place-holder pt-20 pb-8 box-border lg:pt-0'>
      <div
        className='absolute top-0 h-full w-full bg-cover bg-no-repeat data-[state=loading]:opacity-0 data-[state=show]:animate-fadeIn'
        style={{ backgroundImage: `url(${bgImgSrc})` }}
        data-state={isLoading ? 'loading' : 'show'}
      />
      <div className='container relative mx-auto text-white py-4 box-border'>
        <HomeLoading isLoading={isLoading}>
          <AnimatedComponent animatedClass='fadeIn'>
            <div className='flex flex-wrap items-center mb-4'>
              <div className='ml-1 text-left w-full sm:w-7/12 xl:w-6/12'>
                <h1 className='mb-6 font-semibold text-2xl md:text-3xl lg:text-5xl 2xl:text-6xl'>{title}</h1>
                <p className='text-base lg:text-xl'>{subTitle}</p>
              </div>
            </div>
            <div className='flex gap-x-4'>
              <div
                onClick={handleScrollToDownload}
                className='px-4 py-2 box-border bg-white text-primary rounded-3xl cursor-pointer text-semibold'
              >
                Tải ứng dụng
              </div>
              <div className='flex items-center gap-x-2 cursor-pointer' onClick={() => setIsOpenVideoModal(true)}>
                <PlayCircleIcon className='w-8 h-8' color='white' />
                <p className='text-semibold'>Xem video</p>
              </div>
            </div>
          </AnimatedComponent>
        </HomeLoading>
      </div>
      <VideoModal isOpen={isOpenVideoModal} setIsOpen={setIsOpenVideoModal} videoSrc={videoSrc} />
    </div>
  );
};

export default HeroBanner;
