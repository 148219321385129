import { ICON_NEWS_SPEAKER } from 'constant/Icon';
import newIcon from 'assets/images/new_icon.png';
import { Link } from 'react-router-dom';
import useAxios from 'hooks/useAxios';
import HeroBannerSuspense from 'components/utils/HeroBannerSuspense';
import { Suspense, lazy } from 'react';
import { getSubDomain } from 'components/utils/common/Domain';

const HeroBanner = lazy(() => import('components/news/HeroBanner'));
const NewsCol = lazy(() => import('components/news/NewsCol'));

const banners = [
  'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2FFrame%202071856183.png?alt=media&token=28215aa1-af6a-414d-bcef-909576fb8ee2&_gl=1*18osp1o*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5OTI1NTE2My45LjEuMTY5OTI1NTI2Ny42MC4wLjA.',
  'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2FFrame%202071856186.png?alt=media&token=947f21b0-b65a-44fe-82aa-72b62955e21d&_gl=1*zhcgen*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5OTI1NTE2My45LjEuMTY5OTI1NTI5NC4zMy4wLjA.'
];

export default function News() {
  const subDomain = getSubDomain();

  const { data: latestNews } = useAxios(`/public/tinTuc/getSoLuongTinTucMoiNhat/${subDomain}?pageSize=10`);
  const { data: categoryWithNews } = useAxios(`/public/chuyenMucTinTuc/getChuyenMucTinTuc/${subDomain}?pageSize=4`);

  const divideNews = (categoryWithNews) => {
    let result = [];
    const filteredCategoryNoNews = categoryWithNews.filter((categoryWithNew) => categoryWithNew.tinTuc.length);
    for (var i = 0; i < filteredCategoryNoNews.length; i += 3) {
      result.push(filteredCategoryNoNews.slice(i, i + 3));
    }
    return result;
  };

  return (
    <div className=''>
      <Suspense fallback={<HeroBannerSuspense />}>
        <HeroBanner />
      </Suspense>
      {!!latestNews?.length && (
        <div className='container flex mx-auto mt-4'>
          <div className='w-[100px] bg-white'>{ICON_NEWS_SPEAKER()}</div>
          <div className='overflow-hidden mx-auto'>
            <div class='animate-marquee hover:pause h-full flex items-center gap-x-12 font-semibold'>
              {latestNews.map((latestNew) => (
                <Link
                  className='flex items-center hover:text-primary duration-100 cursor-pointer'
                  title={latestNew.tieuDeTinTuc}
                  to={`/tin-tuc/${latestNew.chuyenMucTinTuc.slugUrl}/${latestNew.slugUrl}`}
                >
                  <img src={newIcon} alt='new-icon' />
                  <span className='whitespace-nowrap'>{latestNew.tieuDeTinTuc}</span>
                </Link>
              ))}
            </div>
            <div class='animate-marquee2 hover:pause-none h-full flex items-center gap-x-12 font-semibold'>
              {latestNews.map((latestNew) => (
                <Link
                  className='flex items-center hover:text-primary duration-100 cursor-pointer'
                  title={latestNew.tieuDeTinTuc}
                  to={`/tin-tuc/${latestNew.chuyenMucTinTuc.slugUrl}/${latestNew.slugUrl}`}
                >
                  <img src={newIcon} alt='new-icon' />
                  <span className='whitespace-nowrap'>{latestNew.tieuDeTinTuc}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
      <Suspense fallback={null}>
        {!!categoryWithNews?.length &&
          divideNews(categoryWithNews).map((newCols, index) => (
            <div>
              <div className='container mx-auto my-6 xl:my-12 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-8 lg:gap-x-12 gap-y-12'>
                {newCols.map(
                  (newCol) =>
                    !!newCol.tinTuc.length && (
                      <NewsCol title={newCol.tenChuyenMuc} news={newCol.tinTuc} href={newCol.slugUrl} />
                    )
                )}
              </div>
              <img className='w-full' src={banners[index]} alt='' />
            </div>
          ))}
      </Suspense>
    </div>
  );
}
