import { useState, useEffect } from 'react';
import axiosInstance from 'helpers/axios';

const useAxios = (url, options) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (url) {
        try {
          setLoading(true);
          const response = await axiosInstance.get(url, { params: options });
          setData(response.data);
          if (response.data.status === 'FAILED') {
            setError(response.data.message);
          }
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [url, options]);

  return { data, loading, error };
};

export default useAxios;
