const technologies = [
  {
    title: 'Bảo tàng số',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'VR360 hỗ trợ người dùng xem được toàn cảnh bảo tàng số'
  },
  {
    title: 'Trợ lý ảo',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Được giải đáp thắc mắc bởi trí tuệ nhân tạo AI. Là xu hướng công nghệ được đón đầu'
  },
  {
    title: 'Thông báo nội bộ',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Được xem những thông báo trong phạm vi nội bộ nhanh nhất ở đơn vị'
  },
  {
    title: 'Nhiệm vụ',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Hỗ trợ phân công nhiệm vụ và thực hiện nhiệm vụ Đảng trong tổ chức'
  },
  {
    title: 'Nghị quyết',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Đưa ra nghị quyết cho buổi sinh hoạt Đảng, Chuyên đề, phục vụ cho công tác tổ chức Đảng'
  },
  {
    title: 'Bảo tàng số',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'VR360 hỗ trợ người dùng xem được toàn cảnh bảo tàng số'
  },
  {
    title: 'Trợ lý ảo',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Được giải đáp thắc mắc bởi trí tuệ nhân tạo AI. Là xu hướng công nghệ được đón đầu'
  },
  {
    title: 'Thông báo nội bộ',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Được xem những thông báo trong phạm vi nội bộ nhanh nhất ở đơn vị'
  },
  {
    title: 'Nhiệm vụ',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Hỗ trợ phân công nhiệm vụ và thực hiện nhiệm vụ Đảng trong tổ chức'
  },
  {
    title: 'Nghị quyết',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Đưa ra nghị quyết cho buổi sinh hoạt Đảng, Chuyên đề, phục vụ cho công tác tổ chức Đảng'
  },
  {
    title: 'Bảo tàng số',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'VR360 hỗ trợ người dùng xem được toàn cảnh bảo tàng số'
  },
  {
    title: 'Trợ lý ảo',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Được giải đáp thắc mắc bởi trí tuệ nhân tạo AI. Là xu hướng công nghệ được đón đầu'
  },
  {
    title: 'Thông báo nội bộ',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Được xem những thông báo trong phạm vi nội bộ nhanh nhất ở đơn vị'
  },
  {
    title: 'Nhiệm vụ',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Hỗ trợ phân công nhiệm vụ và thực hiện nhiệm vụ Đảng trong tổ chức'
  },
  {
    title: 'Nghị quyết',
    imgSrc:
      'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Ficon1.png?alt=media&token=55a6dc20-ef1c-49be-aef4-db2fd28512b6&_gl=1*16ejqqa*_ga*MTI1MTgzMzg4Ny4xNjk4NzM2ODE2*_ga_CW55HF8NVT*MTY5ODczOTUxNy4yLjEuMTY5ODczOTUyMC41Ny4wLjA.',
    description: 'Đưa ra nghị quyết cho buổi sinh hoạt Đảng, Chuyên đề, phục vụ cho công tác tổ chức Đảng'
  }
];

export default technologies;
