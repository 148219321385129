const whySTDV = [
  {
    title: 'Nghiên cứu, học tập',
    img: 'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Fitem1.png?alt=media&token=32d54278-9783-4598-9ffd-c817bb810131&_gl=1*qqn5y1*_ga*OTU4ODE0NDM3LjE2OTg2MzU4MDY.*_ga_CW55HF8NVT*MTY5ODY0MTA4NS4zLjEuMTY5ODY0MTEzMy4xMi4wLjA.',
    description:
      '" Hỗ trợ đảng viên trong việc nghiên cứu, học tập các nghị quyết, quy định, chỉ thị, kết luận của Trung ương và của cấp ủy tỉnh; đồng thời cập nhật kịp thời, chính xác những thông tin thời sự của Đảng, Nhà nước, của tỉnh và các cấp ủy Đảng.'
  },
  {
    title: 'Đổi mới, nâng cao',
    img: 'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Fitem2.png?alt=media&token=142462c5-fb67-4fda-9737-78094aba2d7b&_gl=1*1lwib5u*_ga*OTU4ODE0NDM3LjE2OTg2MzU4MDY.*_ga_CW55HF8NVT*MTY5ODY0MTA4NS4zLjEuMTY5ODY0MTE1MC42MC4wLjA.',
    description:
      '" Hỗ trợ đảng viên trong việc nghiên cứu, học tập các nghị quyết, quy định, chỉ thị, kết luận của Trung ương và của cấp ủy tỉnh; đồng thời cập nhật kịp thời, chính xác những thông tin thời sự của Đảng, Nhà nước, của tỉnh và các cấp ủy Đảng.'
  },
  {
    title: 'Số hóa nghiệp vụ Đảng',
    img: 'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Fitem3.png?alt=media&token=8a19d9f5-0fe2-4f9e-bae3-c0538cc32f45&_gl=1*yonz6t*_ga*OTU4ODE0NDM3LjE2OTg2MzU4MDY.*_ga_CW55HF8NVT*MTY5ODY0MTA4NS4zLjEuMTY5ODY0MTE2NC40Ni4wLjA.',
    description:
      '" Hỗ trợ đảng viên trong việc nghiên cứu, học tập các nghị quyết, quy định, chỉ thị, kết luận của Trung ương và của cấp ủy tỉnh; đồng thời cập nhật kịp thời, chính xác những thông tin thời sự của Đảng, Nhà nước, của tỉnh và các cấp ủy Đảng.'
  },
  {
    title: 'Nghiên cứu, học tập',
    img: 'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Fitem1.png?alt=media&token=32d54278-9783-4598-9ffd-c817bb810131&_gl=1*qqn5y1*_ga*OTU4ODE0NDM3LjE2OTg2MzU4MDY.*_ga_CW55HF8NVT*MTY5ODY0MTA4NS4zLjEuMTY5ODY0MTEzMy4xMi4wLjA.',
    description:
      '" Hỗ trợ đảng viên trong việc nghiên cứu, học tập các nghị quyết, quy định, chỉ thị, kết luận của Trung ương và của cấp ủy tỉnh; đồng thời cập nhật kịp thời, chính xác những thông tin thời sự của Đảng, Nhà nước, của tỉnh và các cấp ủy Đảng.'
  },
  {
    title: 'Đổi mới, nâng cao',
    img: 'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Fitem2.png?alt=media&token=142462c5-fb67-4fda-9737-78094aba2d7b&_gl=1*1lwib5u*_ga*OTU4ODE0NDM3LjE2OTg2MzU4MDY.*_ga_CW55HF8NVT*MTY5ODY0MTA4NS4zLjEuMTY5ODY0MTE1MC42MC4wLjA.',
    description:
      '" Hỗ trợ đảng viên trong việc nghiên cứu, học tập các nghị quyết, quy định, chỉ thị, kết luận của Trung ương và của cấp ủy tỉnh; đồng thời cập nhật kịp thời, chính xác những thông tin thời sự của Đảng, Nhà nước, của tỉnh và các cấp ủy Đảng.'
  },
  {
    title: 'Số hóa nghiệp vụ Đảng',
    img: 'https://firebasestorage.googleapis.com/v0/b/save-image-15416.appspot.com/o/landingpage%2Fitem3.png?alt=media&token=8a19d9f5-0fe2-4f9e-bae3-c0538cc32f45&_gl=1*yonz6t*_ga*OTU4ODE0NDM3LjE2OTg2MzU4MDY.*_ga_CW55HF8NVT*MTY5ODY0MTA4NS4zLjEuMTY5ODY0MTE2NC40Ni4wLjA.',
    description:
      '" Hỗ trợ đảng viên trong việc nghiên cứu, học tập các nghị quyết, quy định, chỉ thị, kết luận của Trung ương và của cấp ủy tỉnh; đồng thời cập nhật kịp thời, chính xác những thông tin thời sự của Đảng, Nhà nước, của tỉnh và các cấp ủy Đảng.'
  }
];

export default whySTDV;
